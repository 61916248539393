import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave from "src/components/nonbmaWave"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_cb from "src/components/btf_cb"


const wavelpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`


       .footerlinks-module--br {
         display: none!important;
       }

       .btf_cb-module--btfCb button.btf_cb-module--install {
         background: #39b54a;
         padding: 20px;
         font-size: 30px;
         border-radius: 32px;
         box-shadow: 0 10px 20px -10px #23e332;
         margin-bottom: 25px;
       }

       #ctadisclosuresearch-module--ctadisclaimersearch {
         width: 80%;
         margin: 0 auto;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <NonbmaWave data={wavelpData}></NonbmaWave>
      <Btf_cb data={btfData}></Btf_cb>
      </section>
    </HomepageLayout>
  )
}
